export function opinionsSlider() {
  document.addEventListener('DOMContentLoaded', function () {
    const qualitySliders = document.querySelectorAll('.blockOpinions__slider');
    if (!qualitySliders.length) return;

    qualitySliders.forEach((slider) => {
      const sliderInstance = new window.splide(slider, {
        gap: '30px',
        arrows: false,
        perPage: 3,
        pagination: false,
        drag: true,
        breakpoints: {
          1200: {
            perPage: 2
          },
          800: {
            perPage: 1
          },
        },
      });

      const prevButton = slider.previousElementSibling.querySelector('.custom-prev');
      const nextButton = slider.previousElementSibling.querySelector('.custom-next');
      const progressBar = slider.parentNode.querySelector('.progress-bar');

      prevButton.addEventListener('click', () => {
        sliderInstance.go('-1');
      });

      nextButton.addEventListener('click', () => {
        sliderInstance.go('+1');
      });

      sliderInstance.on('mounted move', () => {
        let end = sliderInstance.Components.Controller.getEnd() + 1;
        let rate = Math.min((sliderInstance.index + 1) / end, 1);

        progressBar.style.maxWidth = `${100 * rate}%`;
      });

      sliderInstance.mount();
    });

  });
}
