export function initPageWrapSlider() {
    document.addEventListener('DOMContentLoaded', function () {
        let main = new window.splide('.splidePrimary', {
            type: 'fade',
            rewind: true,
            pagination: false,
            arrows: false,
        });

        let thumbnails = new window.splide('.splideNav', {
            perPage: 6,
            gap: '17px',
            rewind: true,
            pagination: false,
            isNavigation: true,
            arrows: true,
            breakpoints: {
                1024: {
                    perPage: 4,
                },
                767: {
                    perPage: 3,
                }
            }
        });

        thumbnails.on('overflow', function (isOverflow) {
            if (!isOverflow) {
                thumbnails.go(0);

                thumbnails.options = {
                    arrows: isOverflow,
                    pagination: isOverflow,
                    drag: isOverflow,
                    clones: isOverflow ? undefined : 0,
                };
            }
        });

        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();
    })
}