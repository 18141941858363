
import Splide from '@splidejs/splide';

window.splide = Splide;
window.lightbox = require('simple-lightbox');

import { navInit } from './modules/navigations';
import { themeToggle } from './modules/theme-toggle';
import { initCollectionsSlider } from '../../includes/blocks/block-collection-slider/script';
import { initSearchModal } from './modules/searchModal';
import { heroHomeScript } from '../../includes/blocks/block-hero-slider/script';
import { productSlider } from '../../includes/blocks/block-product-slider/script';
import { qualitySlider } from '../../includes/blocks/block-quality-slider/script';
import { linkSliderScript } from '../../includes/blocks/block-link-slider/script';
import { initBlogCategorySlider } from './modules/blogCategorySlider';
import { blockFaq } from './modules/collapse';
import { shortVideoScript } from '../../includes/blocks/block-video/script';
import { initBlogSlider } from '../../includes/blocks/block-blog-slider/script';
import { heroScript } from '../../includes/blocks/block-hero/script'
import { sliderCategory } from '../../includes/blocks/block-slider-category/script'
import { initProductCart } from './modules/productCart';
import { initGalleryLoop } from "../../includes/blocks/block-gallery-loop/script";
import { initFindSalon } from '../../includes/blocks/block-find-salon/script'
import { blockEmployeeScript } from '../../includes/blocks/block-employee/scripts'
import { initSalonStepSlider } from '../../includes/blocks/block-salon-steps/script';
import { initNewsModal } from './modules/modalNews';
import { initJobOffersModal } from './modules/modalJobOffers';
import { initSingleJobOffersModal } from './modules/modalSingleJobOffers';
import { initChooseSalon } from './modules/chooseSalon';
import { initRealisationsGallery } from '../../includes/blocks/block-realisations-gallery/script';
import { initSalonHeroScript } from '../../includes/blocks/block-salon-hero-slider/script';
import { initSalonGallery } from '../../includes/blocks/block-salon-gallery/script';
import { initCertificatesSlider } from '../../includes/blocks/block-salon-certificates/script';
import { salonProductSlider } from '../../includes/blocks/block-salon-products/script';
import { salonInfo } from '../../includes/blocks/block-salon-info/script';
import { initSalonCptScripts } from './modules/salonCptScripts';
import { productsList } from '../../includes/blocks/block-products-list/script';
import { initBulletsSlider } from '../../includes/blocks/block-bullets/script';
import { initFaq } from "../../includes/blocks/block-faq/script";
import { initSteps } from "../../includes/blocks/block-steps/script";
import { opinionsSlider } from "../../includes/blocks/block-opinions/script";
import { initPageWrapSlider } from "./modules/pageWrapSlider";

navInit();

if (document.querySelector('#theme-toggle')) {
    themeToggle();
}

if (document.querySelector('.blockSteps')) {
    initSteps();
}

if (document.querySelector('.blockCollectionSlider__slider')) {
    initCollectionsSlider();
}

if (document.querySelector('.header__search-btn')) {
    initSearchModal();
}

if (document.querySelector('.blockHeroSlider')) {
    heroHomeScript();
}

if (document.querySelector('.blockSalonHeroSlider')) {
    initSalonHeroScript();
}


if (document.querySelector('.blockProductSlider')) {
    productSlider();
}

if (document.querySelector('.blockQualitySlider')) {
    qualitySlider();
}

if (document.querySelector('.blockLinkSlider')) {
    linkSliderScript();
}

if (document.querySelector('.blogPage__categoryWrapper')) {
    initBlogCategorySlider();
}

if (document.querySelector('.blockFaq')) {
    blockFaq();
}

if (document.querySelector('.blockVideo__short')) {
    shortVideoScript();
}

if (document.querySelector('.blockBlogSlider__slider')) {
    initBlogSlider();
}

if (document.querySelector('.blockHeroCard__scroll')) {
    heroScript();
}


if (document.querySelector('.blockSliderCategory')) {
    sliderCategory();
}

if (document.querySelector('.blockSubcategoryList__offerTypes')) {
    initProductCart();
}

if (document.querySelector('.blockGalleryLoop')) {
    initGalleryLoop();
}

if (document.querySelector('.blockFindSalon')) {
    initFindSalon();
}

if (document.querySelector('.blockEmployee')) {
    blockEmployeeScript();
}

if (document.querySelector('.blockSalonSteps')) {
    initSalonStepSlider();
}

if (document.querySelector('.blockSalonNews')) {
    initNewsModal();
    initJobOffersModal();
}

if (document.querySelector('.jobOffer')) {
    initSingleJobOffersModal();
}

if (document.querySelector('.chooseSalon')) {
    initChooseSalon();
}


if (document.querySelector('.blockRealisationsGallery__loop')) {
    initRealisationsGallery();
}

if (document.querySelector('.blockSalonGallery__gallery')) {
    initSalonGallery();
}

if (document.querySelector('.blockSalonCertificates__img')) {
    initCertificatesSlider();
}


if (document.querySelector('.blockSalonProducts__slider')) {
    salonProductSlider();
}

if (document.querySelector('.blockSalonInfo')) {
    salonInfo();
}

if (document.querySelector('.productsContainer--show')) {
    productsList();
}

if (document.querySelector('.blockBullets')) {
    initBulletsSlider()
}

if (document.querySelector('.blockFaq')) {
    initFaq();
}

if (document.querySelector('.blockOpinions')) {
    opinionsSlider();
}

if (document.querySelector('.singleWrap__gallery')) {
    initPageWrapSlider();
}

setTimeout(function () {
    if (document.querySelector('.searchbox__submit')) {
        initSalonCptScripts();
    }
}, 500);
